<template>
  <div class="container">
    <div
      style="border-radius: 5px; padding: 10px; margin-bottom: 10px"
      class="titleBox"
    >
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        <img
          style="width: 50px; height: auto; display: block"
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E5%85%A8%E6%97%B6%E4%BF%9Dlogo1.png"
        />
        <div
          style="margin: 0 18px; font-size: 18px; font-weight: bold"
          v-if="user != null"
        >
          {{ user.nickName }}
        </div>
        <img
          @click="loginBtn"
          style="width: 18px; height: auto; display: block"
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E5%88%87%E6%8D%A2%403x.png"
        />
      </div>
      <div>
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <img
            style="
              width: 15px;
              height: auto;
              display: block;
              margin-right: 10px;
            "
            src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E8%B4%A6%E6%88%B7%E4%BD%99%E9%A2%9D%403x.png"
          />
          <div style="font-weight: bold; font-size: 13px">账户余额</div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 25px;
            box-sizing: border-box;
          "
        >
          <div>
            <text
              style="
                color: red;
                font-weight: bold;
                font-size: 20px;
                margin-right: 3px;
              "
              >{{ money }}</text
            >元
          </div>
          <div>
            <van-button
              style="padding: 0 20px; border: none"
              type="info"
              color="rgb(216 0 0)"
              size="small"
              @click="toPay"
              >充值</van-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        background: #fff;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
        box-shadow: 0px 10px 10px 1px rgba(73, 144, 236, 0.089) inset;
      "
    >
      <div style="display: flex; align-items: center; margin-bottom: 10px">
        <img
          style="width: 15px; height: auto; display: block; margin-right: 10px"
          src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E6%8A%80%E6%9C%AF%E6%94%AF%E6%8C%81%E6%9C%8D%E5%8A%A1%E7%83%AD%E7%BA%BF%403x.png"
        />
        <div style="font-weight: bold; font-size: 13px">服务热线</div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <img
          style="width: 100%; height: auto; display: block"
          src="https://quanshibao.oss-cn-shanghai.aliyuncs.com/1882265051856764930.png"
        />
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div
        style="
          width: 60%;
          background: #fff;
          border-radius: 5px;
          height: 80px;
          padding: 10px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 10px 10px 1px rgba(73, 144, 236, 0.089) inset;
        "
      >
        <div style="border-right: 1px solid #ccc; width: 50%">
          <div style="display: flex; align-items: center; margin-bottom: 10px">
            <img
              style="
                width: 15px;
                height: auto;
                display: block;
                margin-right: 10px;
              "
              src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E4%BA%BA%E6%95%B0%403x.png"
            />
            <div style="font-weight: bold; font-size: 13px">在保人数</div>
          </div>
          <div style="text-align: center; font-size: 13px">
            <span style="font-size: 18px; font-weight: bold; color: #3e77db">{{
              total
            }}</span
            >人
          </div>
        </div>
        <div style="width: 50%; text-align: center; font-size: 12px">
          <div v-for="(item, index) in category" :key="index">
            {{ item.name }}<span style="margin-right: 5px">:</span
            >{{ item.value }}
          </div>
        </div>
      </div>
      <div
        style="
          width: 38%;
          background: #fff;
          height: 80px;
          border-radius: 5px;
          padding: 10px;
          margin-bottom: 10px;
          box-shadow: 0px 10px 10px 1px rgba(73, 144, 236, 0.089) inset;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <img
            style="
              width: 15px;
              height: auto;
              display: block;
              margin-right: 10px;
            "
            src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E6%A1%88%E4%BB%B6%403x.png"
          />
          <div style="font-weight: bold; font-size: 13px">案件数量</div>
        </div>
        <div style="text-align: center; font-size: 13px">
          <span
            style="font-size: 18px; font-weight: bold; color: #3e77db"
            >{{case}}</span
          >件
        </div>
      </div>
    </div>
    <div
      style="
        width: 100%;
        background: #fff;
        border-radius: 10px;
        padding: 10px;
        box-sizing: border-box;
      "
    >
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="consumptionRecords"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E6%B6%88%E8%B4%B9%E8%AE%B0%E5%BD%952.png"
            />
          </van-col>
          <van-col span="19" style="text-align: left; font-weight: bold"
            >费用清单</van-col
          >
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="insuranceRecords"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E5%8A%A0%E5%87%8F%E4%BF%9D%E8%AE%B0%E5%BD%952.png"
            />
          </van-col>
          <van-col span="19" style="text-align: left; font-weight: bold"
            >加减保记录</van-col
          >
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="claimInquiry"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E7%90%86%E8%B5%94%E6%A1%88%E4%BB%B6%E6%9F%A5%E8%AF%A22.png"
            />
          </van-col>
          <van-col span="19" style="text-align: left; font-weight: bold"
            >理赔案件查询</van-col
          >
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="personnelQuery"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E5%9C%A8%E4%BF%9D%E4%BA%BA%E5%91%98%E6%9F%A5%E8%AF%A22.png"
            />
          </van-col>
          <van-col span="19" style="text-align: left; font-weight: bold"
            >在保人员查询</van-col
          >
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="queryCompany"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E7%94%A8%E5%B7%A5%E4%BC%81%E4%B8%9A%E6%9F%A5%E8%AF%A22.png"
            />
          </van-col>
          <van-col span="19" style="text-align: left; font-weight: bold"
            >用工企业查询</van-col
          >
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="aboutBtn"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC2.png"
            />
          </van-col>
          <van-col span="19" style="text-align: left; font-weight: bold"
            >关于我们</van-col
          >
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="outLogin"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://quanshibao.oss-cn-shanghai.aliyuncs.com/%E5%85%A8%E6%97%B6%E4%BF%9D%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84/%E9%80%80%E5%87%BA%E7%99%BB%E5%BD%95%403x.png"
            />
          </van-col>
          <van-col span="19" style="text-align: left; font-weight: bold"
            >退出登录</van-col
          >
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
    </div>
    <div style="height: 3.5rem"></div>
  </div>
</template>
<script>
import { Modal } from "ant-design-vue";
import { showToast } from "vant";
import { showNotify } from "vant";
import { userInfo, userBehavior } from "@/api/api";
export default {
  data() {
    return {
      user: {},
      case: "0",
      money: "0",
      category: [],
    };
  },
  created() {
    if (this.user == null) {
      this.$router.push({
        name: "login",
      });
    } else {
      this.user = JSON.parse(localStorage.getItem("user"));
    }
    userInfo().then((res) => {
      if (res.code == "0") {
        this.case = res.userInfo.case;
        this.money = res.userInfo.money;
        this.category = res.userInfo.onLine.category;
        this.total = res.userInfo.onLine.total;
      } else {
        this.$router.push({
          name: "login",
        });
      }
    });
  },
  methods: {
    loginBtn() {
      this.$router.push({
        name: "login",
      });
    },
    // 充值
    toPay() {
      userBehavior({ stayPage: "toPay" });
      this.$router.push({
        name: "home/toPay",
      });
    },
    // 费用清单
    consumptionRecords() {
      userBehavior({ stayPage: "consumptionRecords" });
      this.$router.push({
        name: "home/consumptionRecords",
      });
    },
    // 加减保记录
    insuranceRecords() {
      userBehavior({ stayPage: "insuranceRecords" });
      this.$router.push({
        name: "home/insuranceRecords",
      });
    },
    // 理赔案件查询
    claimInquiry() {
      userBehavior({ stayPage: "claimInquiry" });
      this.$router.push({
        name: "home/claimInquiry",
      });
    },
    // 在保人员查询
    personnelQuery() {
      userBehavior({ stayPage: "personnelQuery" });
      this.$router.push({
        name: "home/personnelQuery",
      });
    },
    // 用工企业查询
    queryCompany() {
      userBehavior({ stayPage: "queryCompany" });
      this.$router.push({
        name: "home/queryCompany",
      });
    },
    // 关于我们
    aboutBtn() {
      this.$router.push({
        name: "home/about",
      });
    },
    // 退出登录
    outLogin() {
      Modal.confirm({
        title: "确定要退出登录吗？",
        content: "",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userInfo");
          this.$router.push({
            name: "login",
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.titleBox {
  background: url("@/assets/头部背景.png");
  background-size: 100% 100%;
}
</style>